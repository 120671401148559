







































































import { Component, Prop, mixins } from 'nuxt-property-decorator';

import { usePerformanceEvents } from '@/composables/performance-events';
import { formatSv } from '@/composables/datefns';

import { ComposableMixin } from '@/mixins/composable';
import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockUpcomingShows extends mixins(
  ComposableMixin(usePerformanceEvents),
) {
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  modalOpen = false;

  created () {
    this.performanceEventsLoading = true;
  }

  get shows () {
    return this.performanceEvents
      .slice(0, 8)
      .map((event) => {
        return {
          title: event.performance.title,
          slug: event.performance.pageUrl,
          subtitle: `${formatSv(new Date(event.startDate), "d MMM 'kl.' HH:mm")} på ${event.locationStage}`,
          image: event.performance.image,
          cancelled: event.cancelled,
          soldOut: event.soldOut,
          bookingLink: event.purchaseUrl,
        };
      });
  }

  openModal (e: boolean) {
    this.modalOpen = e;
  }
}
